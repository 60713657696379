.inputPickerWrapper{
    border-top: 1px solid #e5e5ea;
    width: 75%;
}
.inputPickerWrapper:hover {
    border-top: 1px solid #3498ff;
}

.inputPickerWrapper1{
    border-top: 1px solid #e5e5ea;
    width: 100%;
}
.inputPickerWrapper1:hover {
    border-top: 1px solid #3498ff;
}
